.chips-layout {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 140px !important;
  min-width: 100px !important;
  text-align: left !important;
  padding: 5px 10px;
}

.chips-layout-open {
  max-width: 140px !important;
  min-width: 100px !important;
  text-align: left !important;
  padding: 5px 10px;
  white-space: pre-wrap !important;
}

.view-more {
  float: right;
  margin-top: 0px;
  margin-right: 18px;
  font-size: 11px;
  color: #1e90ff;
}

/* .modal-video-movie-wrap {
  width: 25rem !important;
  text-align: center;
} */

/* .modal-content {
  background-color: transparent !important;
} */

.video-icons-style {
  font-size: 25px;
  position: absolute;
  right: 10px;
  top: 2px;
  cursor: pointer;
}
