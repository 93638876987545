/*
@File: Exolot - Multipurpose Landing Page HTML5 Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

==== INDEX PAGE CSS

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - Subscribe Area CSS
** - Welcome Area CSS
** - About Area CSS
** - Services Area CSS
** - Team Area CSS
** - Portfolio Area CSS
** - Features Area CSS
** - Feedback Area CSS
** - Pricing Area CSS
** - Blog Area CSS
** - Work With Us Area CSS
** - Partner Area CSS
** - Contact Area CSS
** - Footer Area CSS
** - Right Side Modal CSS
** - Go Top CSS

*/
/*================================================
Default CSS
=================================================*/
// $family1: unquote("Poppins");
// $family2: unquote("Ubuntu");
// $font_family1: "Poppins", sans-serif;
// $font_family2: "Ubuntu", sans-serif;

$family1: unquote('Calibri');
$family2: unquote('Quicksand');
$font_family1: 'Calibri', sans-serif;
$font_family2: 'Quicksand', sans-serif;
$template_color: #21309d;
$template_hover_color: #21309d;

@import url('https://fonts.googleapis.com/css?family=#{$family1}:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css?family=#{$family2}:300,300i,400,400i,500,500i,700,700i');

body {
  padding: 0;
  margin: 0;
  font-display: $font_family1;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.p,
h1,
h2,
h3,
h4,
h5,
h6 p {
  font-family: $font_family2;
}
h1 {
  font-size: 2.5rem;
}
.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}
.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
a {
  display: inline-block;
  outline: 0 !important;
  text-decoration: none !important;
  transition: 0.4s;
}
p {
  font: {
    size: 15px;
  }
  line-height: 1.8;
  color: #8c8b8b;
}
img {
  max-width: 100%;
}
.bg-f9f9f9 {
  background: #f9f9f9;
}
.font-bold * {
  font-weight: 700;
}

/*btn*/
.btn {
  font: {
    weight: 600;
    size: 15px;
  }
  border: none;
  padding: 10px 33px;
  line-height: 1.5;
  text-transform: capitalize;
  border-radius: 50px;

  &:focus {
    outline: 0;
  }
}
button:focus {
  outline: 0;
}
.btn-primary {
  // color: #ffffff;
  // background-color: $template_color;
  // border-color: $template_color;

  // &:hover,
  // &:focus {
  //     background: $template_hover_color;
  //     color: #ffffff;
  //     border-color: $template_hover_color;
  //     box-shadow: unset;
  // }
  color: $template_color;
  background-color: #ffffff;
  border-color: $template_color;

  &:hover,
  &:focus {
    background: #ffffff;
    color: $template_hover_color;
    border-color: #ffffff;
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: unset;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    background: $template_color;
    color: #ffffff;
    border-color: $template_color;
  }
  &.disabled,
  &:disabled {
    color: #ffffff;
    opacity: 1;
    background-color: $template_color;
    border-color: $template_color;
    &:hover,
    &:focus {
      background: $template_hover_color;
      color: #ffffff;
      border-color: $template_hover_color;
      box-shadow: unset;
    }
  }
}

.btn-primary_submit {
  color: #ffffff !important;
  background-color: $template_color !important;
  border-color: $template_color !important;

  &:hover {
    background: #21309d !important;
    // color: $template_hover_color;
    // color: #ffffff !important;
    border-color: #ffffff !important;
    box-shadow: unset !important;
    // border:0.1em solid gray; /* here configure as your needs */
    color: gray !important;
    outline: none !important;
  }
  &:focus {
    color: #ffffff !important;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: unset;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    background: $template_color !important;
    color: gray !important;
    border-color: $template_color !important;
  }
  &.disabled,
  &:disabled {
    color: #ffffff !important;
    opacity: 0.7 !important;
    background-color: $template_color !important;
    border-color: $template_color !important;
    &:hover,
    &:focus {
      background: $template_hover_color !important;
      color: #ffffff !important;
      border-color: $template_hover_color !important;
      box-shadow: unset !important;
      box-shadow: inset 0 -1px 0 #ddd !important;
    }
  }
}

@media (min-width: 769px) {
  .btn-primary_submit {
    color: #ffffff !important;
    background-color: $template_color !important;
    border-color: $template_color !important;

    &:hover {
      background: #21309d !important;
      // color: $template_hover_color;
      color: #ffffff !important;
      border-color: #ffffff !important;
      box-shadow: unset !important;
      // border:0.1em solid gray; /* here configure as your needs */
      color: gray !important;
      outline: none !important;
    }

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: unset;
    }
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      background: $template_color !important;
      color: #ffffff !important;
      border-color: $template_color !important;
    }
    &.disabled,
    &:disabled {
      color: #ffffff !important;
      opacity: 0.7 !important;
      background-color: $template_color !important;
      border-color: $template_color !important;
      &:hover,
      &:focus {
        background: $template_hover_color !important;
        color: #ffffff !important;
        border-color: $template_hover_color !important;
        box-shadow: unset !important;
        box-shadow: inset 0 -1px 0 #ddd !important;
      }
    }
  }
}

/*owl carousel btn*/
.owl-theme {
  .owl-nav {
    margin-top: 0;

    [class*='owl-'] {
      position: absolute;
      left: 40px;
      top: 45%;
      font-size: 30px !important;
      width: 60px;
      height: 60px;
      text-align: center;
      color: #ffffff !important;
      line-height: 50px !important;
      margin: 0;
      background: rgba(0, 0, 0, 0.5) !important;
      border-radius: 30px !important;
      transition: 0.4s;
    }

    [class*='owl-']:hover {
      background: $template_color !important;
      color: #ffffff !important;
    }
    .owl-next {
      left: auto;
      right: 40px;
      cursor: pointer;
    }
    .owl-prev {
      transform: rotate(180deg);
      line-height: 50px;
      cursor: pointer;
    }
  }

  .owl-dots {
    line-height: 0.1;
    margin-top: 40px !important;

    .owl-dot {
      span {
        width: 15px !important;
        height: 15px !important;
        margin: 0 5px !important;
        background: #d7d7d7 !important;
        border-radius: 50% !important;
        transition: 0.4s !important;
      }
      &:hover span,
      &.active span {
        background: $template_color !important;
      }
    }
  }
}
/*form control*/
.form-control {
  height: 45px;
  padding: 15px;
  color: #000000;
  background-color: #eeeeee;
  border: none;
  border-radius: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #eeeeee;
  outline: 0;
  box-shadow: unset;
}
/*section title*/
.section-title {
  text-align: center;
  margin: {
    bottom: 60px;
    top: -8px;
  }
  font-family: $font_family2;

  span {
    color: $template_color;
    display: block;
    text-transform: uppercase;
    margin-bottom: 10px;
    font: {
      size: 14px;
      weight: 700;
    }
  }
  h2 {
    font: {
      size: 30px;
      weight: 400;
    }
    margin: {
      bottom: 15px;
    }

    b {
      font-weight: 700;
    }
  }
  p {
    font-size: 16px;
    max-width: 535px;
    margin: 0 auto;
  }
}
/*================================================
Navbar Area CSS
=================================================*/
.navbar {
  position: fixed;
  width: 100%;
  height: auto;
  padding: 20px 0;
  left: 0;
  top: 0;
  background-color: transparent !important;
  z-index: 999;

  &.navbar-light.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 15px 0;
    background-color: #ffffff !important;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }
}
.navbar-light {
  .navbar-brand {
    color: #ffffff;
    font-size: 28px;
    font-weight: 500;
    font-family: $font_family2;
    cursor: pointer;

    span {
      font-weight: 700;
      color: $template_color;
    }

    &:hover {
      color: #ffffff;
    }
  }

  .navbar-brand img {
    // content: url(../../assets/img/logo4_new.png) !important
    content: url(../../assets/logo/logo-blue.jpg) !important;
  }
  //  .navbar-brand img{

  //     background-image: url("https://cdn.shopify.com/s/files/1/0570/2877/2012/files/FDA_Logo_2.png?v=1626863124") !important;
  //   }

  .navbar-nav {
    .nav-item {
      padding: 0 14px;

      .nav-link {
        color: #ffffff;
        padding: 0;
        font-family: $font_family2;
        font-weight: 500;
        // font-size: 15px;
        font-size: 17px;
        cursor: pointer;

        &:hover {
          color: darkgray;
        }

        span {
          position: relative;
          display: inline-block;
          width: 35px;
          height: 3px;
          background: $template_color;
          right: 0;
          top: -3px;
          transition: 0.4s;

          &::before,
          &:after {
            content: '';
            width: 25px;
            height: 3px;
            background: $template_color;
            position: absolute;
            right: 0;
            transition: 0.4s;
          }
          &::before {
            top: -7px;
          }
          &::after {
            bottom: -7px;
          }
        }

        &:hover span::before,
        &:hover span::after {
          width: 35px;
        }
        &.active {
          color: white;
        }
      }

      &.active .nav-link {
        color: white;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &.is-sticky {
    .navbar-brand {
      color: #222222;
    }
    .navbar-brand img {
      // content: url(../../assets/img/IB_inverse_logo_RZ.png)!important;
      content: url(../../assets/logo/logo-white.png) !important;
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: #222222;

          &:hover {
            color: $template_color;
          }
          &.active {
            color: $template_color;
          }
        }

        &.active .nav-link {
          color: $template_color;
        }
      }
    }
  }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  height: 100vh;
  overflow: hidden;
  background: {
    // color: #dddddd;
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  position: relative;
  z-index: 1;

  // &::before {
  //     content: "";
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 100%;
  //     height: 100%;
  //     background: #000;
  //     opacity: 0.6;
  //     z-index: -1;
  // }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #21309d;
    // opacity: 0.9;
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background: {
      // position: center center;
      position: center center;
      image: url(../../assets/img/White_icon2.png);
      repeat: no-repeat;
    }
    z-index: -1;
  }

  .scroll_down {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 50px;
    width: 30px;
    z-index: 5;
    bottom: 30px;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 20px;
    cursor: pointer;
    animation: movebounce 3s linear infinite;
    outline: none;
    &::before {
      position: absolute;
      top: 10px;
      left: 50%;
      content: '';
      width: 6px;
      height: 6px;
      margin-left: -3px;
      background-color: #fff;
      border-radius: 100%;
      animation: scroll_down_btn-animation 2s infinite;
      box-sizing: border-box;
    }
  }
}
button.modal-video-close-btn {
  cursor: pointer;
}

// .item-bg-one {
// background-image: url(../../assets/img/main-banner2.jpg);
// }

.item-bg-one {
  background-image: url(../../assets/img/main-banner1.jpg);
}
.item-bg-two {
  background-image: url(../../assets/img/White_icon.png);
}
.item-bg-three {
  background-image: url(../../assets/img/main-banner3.jpg);
}
.hero-content {
  text-align: center;
  max-width: 940px;
  margin: 50px auto 0;

  h5 {
    color: #ffffff;
    text-transform: uppercase;
    font: {
      size: 20px;
      weight: 300;
    }
    margin: 0;
  }
  h1 {
    color: #ffffff;
    font: {
      size: 70px;
      weight: 700;
    }
    margin: 15px 0;
    text-transform: uppercase;

    // span {
    //     color: $template_color;
    // }
    span {
      color: white;
    }
  }
  p {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 30px;
  }

  .video-btn {
    font: {
      family: $font_family2;
      weight: 500;
      size: 15px;
    }
    color: #ffffff !important;
    margin-left: 7px;
    text-transform: capitalize;
    background: transparent;
    border: none;
    cursor: pointer;

    i {
      font-size: 38px;
      position: relative;
      top: 8px;
      margin-right: 7px;
    }
    // &:hover, &:focus {
    //     color: $template_color !important;
    // }
    &:hover,
    &:focus {
      color: #ffffff !important;
    }
  }

  .btn {
    margin-right: 7px;
  }
}
.opacityZero {
  opacity: 0;
}
.opacityOne {
  opacity: 1;
}
.creative-box1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;

  &::before {
    content: '';
    border-right: 50vw solid #ffffff;
    border-top: 60px solid transparent;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 0;
    width: 0;
    z-index: -1;
  }
  &::after {
    content: '';
    position: absolute;
    border-left: 50vw solid #ffffff;
    border-top: 60px solid transparent;
    bottom: 0;
    height: 0;
    left: 0;
    width: 0;
    z-index: -1;
  }
}
.animation-box1 {
  position: absolute;
  left: 100px;
  bottom: 100px;
  animation: movebounce 3s linear infinite;
}
.animation-box2 {
  position: absolute;
  right: 150px;
  top: 150px;
  animation: movebounce 3s linear infinite;
}
.animation-box3 {
  position: absolute;
  left: 100px;
  top: 100px;
  animation: movebounce 3s linear infinite;
}
.animation-box4 {
  position: absolute;
  right: -260px;
  bottom: -260px;
  animation: movebounce 3s linear infinite;
  img {
    max-width: 430px;
  }
}
.animation-box5 {
  position: absolute;
  right: -150px;
  bottom: -150px;
  z-index: -1;
}
.animation-box6 {
  position: absolute;
  right: 5px;
  bottom: 15px;
  z-index: -1;
  animation: movebounce 3s linear infinite;
}
.animation-box7 {
  position: absolute;
  left: 50%;
  bottom: 50px;
  z-index: -1;
  animation: movebounce 3s linear infinite;
}
@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes scroll_down_btn-animation {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes scroll_down_btn-animation {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.wave_box {
  height: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  .wave {
    background: url(../../assets/img/wave.png) repeat-x;
    position: absolute;
    bottom: 0;
    width: 6400px;
    left: 0;
    height: 90px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    &:nth-of-type(2) {
      bottom: -30px;
      animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
      opacity: 1;
    }
  }
}
@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
  background: $template_color;
  padding-top: 50px;
  font-family: $font_family2;
  padding-bottom: 50px;
  position: relative;

  p {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 24px;
  }
  position: relative;

  .form-control {
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 30px;
    color: #ffffff;

    &::placeholder {
      color: #ffffff;
    }
  }

  button {
    position: absolute;
    right: 10px;
    top: 0;
    height: 45px;
    width: 75px;
    background: #ffffff;
    border: none;
    border-radius: 30px;
    font-size: 25px;
    cursor: pointer;
    line-height: 50px;
    color: $template_color;
  }
  .validation-danger,
  .validation-success {
    color: #ffffff;
    margin-top: 10px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 25px;
    background: {
      image: url(../../assets/img/border.png);
      position: center center;
      repeat: repeat;
      size: contain;
    }
    transform: rotate(540deg);
  }
}

/*================================================
Welcome Area CSS
=================================================*/
.single-box {
  text-align: center;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  padding: 30px 20px;
  background: #ffffff;
  position: relative;
  transition: 0.4s;

  i {
    font-size: 50px;
    color: #999999;
    transition: 0.4s;
  }
  &:hover i {
    color: $template_color;
  }
  h3 {
    font: {
      size: 20px;
      weight: 500;
    }
    margin: {
      bottom: 15px;
      top: 20px;
    }
  }
  p {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    border-radius: 0 0 5px 5px;
    left: 0;
    background: $template_color;
    bottom: 0;
    transition: 0.4s;
  }
  &:hover::before {
    width: 100%;
  }
  &:hover {
    transform: translateY(-10px);
  }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
  position: relative;
  overflow: hidden;
  z-index: 1;

  .row {
    position: relative;
  }

  .animation-box2 {
    right: 80px;
    top: 50px;
  }
}
.about-image {
  position: absolute;
  left: -90px;
  top: -50px;
  padding: 0;
  &.desktop-only {
    @media (max-width: 991px) {
      display: none;
    }
  }
  &.mobile-only {
    @media (min-width: 768px) and (max-width: 991px) {
      display: block;
    }
  }
}
.about-title {
  h3 {
    font-size: 30px;
    margin: 0 0 10px;
    font-weight: 400;
  }
  h5 {
    font-size: 18px;
    // text-transform: capitalize;
    text-transform: initial;
    font-weight: 300;
    margin: 0 0 15px;
    span {
      color: $template_color;
      font-weight: 400;
    }
  }
  p {
    margin-bottom: 0;
  }
}
.our-skill {
  margin-top: 25px;

  .single-progress {
    .progress-title {
      font-size: 16px;
      margin: 0 0 8px;
    }
    .progress {
      height: 10px;
      background: #bab2b6;
      border-radius: 10px;
      box-shadow: none;
      margin-bottom: 20px;
      overflow: visible;

      .progress-bar {
        box-shadow: none;
        position: relative;
        background: $template_color;
        border-radius: 10px;
        animation: animate-positive 2s;
      }
      .progress-value {
        font-size: 15px;
        font-weight: 400;
        color: #222222;
        font-family: $font_family2;
        position: absolute;
        top: -28px;
        right: 0;
      }
    }
    &:nth-child(4) .progress {
      margin-bottom: 0;
    }
  }
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@keyframes animate-positive {
  0% {
    width: 0;
  }
}
.our-mission {
  p {
    margin: {
      bottom: 0;
      top: 12px;
    }
  }
}
.why-choose-us {
  text-align: center;
  background: #ffffff;
  margin-top: 20px;

  .single-choose {
    padding: 20px 15px;

    i {
      font-size: 35px;
      color: #777777;
      transition: 0.4s;
    }
    &:hover i {
      color: $template_color;
    }
    h5 {
      font-size: 16px;
      font-weight: 500;
      margin: {
        bottom: 0;
        top: 8px;
      }
    }
  }

  .col-lg-4:nth-child(1) .single-choose {
    border-bottom: 1px solid #eeeeee;
  }
  .col-lg-4:nth-child(2) .single-choose {
    border: {
      bottom: 1px solid #eeeeee;
      right: 1px solid #eeeeee;
      left: 1px solid #eeeeee;
    }
  }
  .col-lg-4:nth-child(3) .single-choose {
    border-bottom: 1px solid #eeeeee;
  }
  .col-lg-4:nth-child(5) .single-choose {
    border: {
      right: 1px solid #eeeeee;
      left: 1px solid #eeeeee;
    }
  }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-bottom: 70px;
  background: {
    image: url(../../assets/img/bg.png);
    attachment: fixed;
    repeat: repeat;
  }
}
.single-services {
  padding: 30px 20px;
  background: #ffffff;
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  transition: 0.4s;

  i {
    font-size: 45px;
    color: #999999;
    transition: 0.4s;
  }
  &:hover i {
    color: $template_color;
  }
  h3 {
    font: {
      size: 20px;
      weight: 500;
    }
    margin: {
      bottom: 15px;
      top: 20px;
    }
  }
  p {
    margin-bottom: 0;
  }
  .bg-number {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 54px;
    color: $template_color;
    opacity: 0.25;
    transition: 0.4s;
  }

  &:hover .bg-number {
    opacity: 1;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  }
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
  position: relative;
  overflow: hidden;

  .animation-box4 {
    right: -300px;
    bottom: -300px;
  }

  .owl-carousel {
    padding: 0;
  }
}
.single-team {
  text-align: center;
  padding: 0 15px;

  ul {
    margin: 0;
    list-style-type: none;
    display: inline-block;
    background: $template_color;
    position: relative;
    top: -20px;
    padding: 6px 30px;
    border-radius: 30px;

    li {
      display: inline-block;

      a {
        color: #ffffff;
        font-size: 15px;
        margin: 0 -3px;
        width: 33px;
        border-radius: 50%;
        border: 1px solid transparent;
        height: 33px;
        text-align: center;
        line-height: 33px;

        &:hover {
          border-color: #ffffff;
          background: #ffffff;
          color: $template_color;
        }
      }
    }
  }
  h3 {
    font: {
      weight: 500;
      size: 20px;
    }
    margin: {
      top: 6px;
      bottom: 6px;
    }
  }
  span {
    display: block;
    font: {
      size: 16px;
      weight: 500;
    }
    color: #999999;
  }
}

/*================================================
Portfolio Area CSS
=================================================*/
.portfolio-area {
  padding-bottom: 60px;
}
.single-work {
  margin-bottom: 40px;
  .work-image {
    display: block;
    position: relative;

    &::before {
      content: '';
      background: $template_color;
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      transition: 0.4s;
    }
    &:hover::before,
    &:focus::before {
      width: 100%;
    }
    .popup-btn {
      position: absolute;
      left: 0;
      top: 50%;
      text-align: center;
      right: 0;
      cursor: pointer;
      color: #ffffff;
      border: 2px solid #ffffff;
      width: 45px;
      margin: 0 auto;
      height: 45px;
      line-height: 40px;
      border-radius: 50%;
      margin-top: 0;
      opacity: 0;
      visibility: hidden;
      display: inline-block;

      &:hover {
        background: #ffffff;
        color: $template_color;
      }
    }
    &:hover .popup-btn,
    &:focus .popup-btn {
      opacity: 1;
      visibility: visible;
      margin-top: -15px;
    }
  }
  h3 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    margin: 25px 0 0;
    line-height: 12px;
  }
}

/*================================================
Features Area CSS
=================================================*/
.features-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: $template_color;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    opacity: 0.2;
    width: 100%;
    background: url(../../assets/img/white-waves.png);
  }
}
.features-img img {
  border-radius: 10px;
}
.single-features {
  position: relative;
  margin-bottom: 30px;
  padding-left: 90px;

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    color: $template_color;
    height: 70px;
    text-align: center;
    line-height: 70px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    font-size: 30px;
    transition: 0.4s;
  }
  &:hover .icon {
    background: transparent;
    color: #ffffff;
  }
  h3 {
    font-size: 22px;
    color: #ffffff;
    margin-bottom: 15px;
  }
  p {
    color: #fff;
    margin-bottom: 0;
    font-weight: 400;
  }
  &:nth-child(3) {
    margin-bottom: 0;
  }
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
  position: relative;
  overflow: hidden;
}
.single-feedback {
  background: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
  padding: 20px;
  border-radius: 5px;
  margin-top: 25px;
  z-index: 1;
  position: relative;
  transition: 0.4s;

  &:hover {
    background: $template_color;
  }
  .client-info {
    position: relative;
    padding-left: 118px;
    margin-bottom: 20px;

    .img {
      position: absolute;
      left: 0;
      top: -45px;
      width: 100px;
      img {
        border-radius: 0 20px 0 0;
      }
    }
    h4 {
      font-size: 20px;
      font-weight: 500;
      -moz-transition: 0.4s;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    span {
      display: block;
      font-size: 14px;
      color: #828893;
      margin-top: 8px;
      transition: 0.4s;
    }
  }
  p {
    transition: 0.4s;
    margin-bottom: 0;
  }
  &:hover p,
  &:hover .client-info h4,
  &:hover .client-info span {
    color: #fff;
  }
  i {
    font-size: 104px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #6f8ba4;
    z-index: -1;
    margin-top: -6px;
    margin-left: -70px;
    opacity: 0.07;
    transition: 0.4s;
  }
  &:hover i {
    color: #ffffff;
    opacity: 0.14;
  }
}

/*================================================
Pricing Area CSS
=================================================*/
.tab-slider-nav {
  .tab-slider {
    .nav-tabs {
      display: block;
      text-align: center;
      margin-bottom: 40px;
      border: none;

      a {
        display: inline-block;
        background: #eeeeee;
        border: none;
        border-radius: 30px;
        margin: 0 5px;
        font: {
          weight: 600;
          size: 15px;
        }
        padding: 9px 35px;
        color: #686868;

        &.active {
          background: $template_color;
          color: #ffffff;
        }
      }
    }
  }
}
.pricing-table {
  text-align: center;
  background-image: url(../../assets/img/bg.png);
  padding-bottom: 35px;
  border-radius: 5px;
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  border: 1px solid transparent;
  transition: 0.4s;

  .pricing-header {
    padding: 40px 20px;
    border-bottom: 1px solid #e4e4e4;
    transition: 0.4s;
    font: {
      size: 18px;
      weight: 700;
      family: $font_family2;
    }
    text-transform: uppercase;
  }
  .price {
    font: {
      size: 34px;
      weight: 700;
    }
    margin: {
      top: 25px;
      bottom: 25px;
    }
    color: $template_color;
  }
  .pricing-features {
    padding: 0;
    margin: 0 0 35px;
    list-style-type: none;

    li {
      font: {
        size: 15px;
        weight: 400;
      }
      color: #777777;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .btn-primary {
    background: transparent;
    text-transform: uppercase;
    color: #777777;
    border: 1px solid $template_color;
    &:hover,
    &:focus {
      background: $template_hover_color !important;
      border-color: $template_hover_color !important;
    }
  }
  &.active,
  &:hover {
    border: 1px solid $template_color;
    .pricing-header {
      background: $template_color;
      border-color: $template_color;
      color: #ffffff;
    }
    .btn-primary {
      background: $template_color;
      color: #ffffff;
    }
  }
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
  padding-bottom: 70px;
}
.single-blog-post {
  margin-bottom: 30px;

  .post-thumbnail {
    overflow: hidden;

    a {
      display: block;
      img {
        transition: 0.4s;
      }
    }
  }

  &:hover .post-thumbnail a img {
    transform: scale(1.3);
  }

  .post-content {
    margin-top: 25px;
    h3 {
      font: {
        size: 20px;
        weight: 700;
      }
      line-height: 30px;
      margin-bottom: 15px;
      a {
        color: #000000;
        &:hover {
          color: $template_color;
        }
      }
    }
    .read-more {
      font-size: 15px;
      color: #777777;
      &:hover {
        color: $template_color;
      }
    }
  }
}

/*================================================
Work With Us Area CSS
=================================================*/
.work-with-us {
  background: {
    color: $template_color;
  }
  z-index: 1;
  text-align: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    opacity: 0.2;
    top: 0;
    width: 100%;
    height: 100%;
    // background: url(../../assets/img/regal.png);
    z-index: -1;
  }
  h3 {
    font: {
      size: 40px;
      weight: 700;
    }
    color: #ffffff;
    margin: {
      bottom: 12px;
      top: -7px;
    }
  }
  span {
    font-size: 20px;
    color: #ffffff;
    display: block;
    margin-bottom: 25px;
  }
  .btn-primary {
    // color: #71c21b;
    color: #21309d;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    &:hover,
    &:focus {
      color: #ffffff;
      background: transparent;
    }
  }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  text-align: center;
  overflow: hidden;
  position: relative;
  .item a {
    display: block;
    position: relative;
    z-index: 1;
  }
  .owl-carousel .owl-item img {
    width: 140px;
  }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-box {
  display: block;
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  padding: {
    left: 115px;
    right: 30px;
    top: 25px;
    bottom: 25px;
  }
  position: relative;
  height: 100%;
  .icon {
    position: absolute;
    left: 25px;
    top: 30px;
    width: 70px;
    height: 70px;
    line-height: 68px;
    font-size: 35px;
    text-align: center;
    border-radius: 50%;
    color: $template_color;
    border: 1px solid $template_color;
    transition: 0.4s;
  }
  &:hover .icon {
    background: $template_color;
    color: #ffffff;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  p {
    margin: 0;
    line-height: 15px;
    font-weight: 400;
    a {
      color: #777777;
      &:hover {
        color: $template_color;
      }
    }
  }
}
.get-in-touch {
  margin-top: 40px;
  h3 {
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
  }
  p {
    margin-bottom: 20px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      display: inline-block;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: $template_color;
      }
    }
  }
}

#contactForm {
  margin-top: 40px;

  textarea.form-control {
    height: auto;
  }

  .form-control {
    background: transparent;
    border: 1px solid #eeeeee;
    border-radius: 5px;
  }
  .list-unstyled li {
    color: red;
  }
  #msgSubmit {
    margin: 0;
    &.text-left {
      margin-top: 20px;
      font-size: 18px;
    }
  }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  padding: 30px 0;
  background: #121314;
  p {
    font-size: 11px;
    margin-bottom: 0;
    color: #d5d5d5;
  }
  ul {
    text-align: right;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: inline-block;
    position: relative;
    font-size: 14px;
    padding-left: 20px;
    &::before {
      content: '';
      position: absolute;
      left: 9px;
      top: 5px;
      background: #ffffff;
      width: 2px;
      height: 12px;
    }
    &:first-child::before {
      display: none;
    }
    a {
      color: #d5d5d5;
      &:hover {
        color: $template_color;
      }
    }
  }
}

/*================================================
Right Side Modal CSS
=================================================*/
.modal.right {
  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 520px;
    height: 100%;
    transform: translate3d(0%, 0, 0);

    .modal-content {
      height: 100%;
      overflow-y: auto;
      border-radius: 0;
      border: none;

      .modal-body {
        padding: 20px;

        p {
          margin-top: 20px;
        }

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;
        }

        .modal-contact-info {
          margin-top: 30px;
          margin-bottom: 20px;

          li {
            position: relative;
            padding-left: 45px;
            font-size: 18px;
            margin-bottom: 25px;

            b {
              display: block;
            }
            i {
              position: absolute;
              left: 0;
              top: 0;
              font-size: 35px;
              color: $template_color;
            }
          }
        }

        .social-links {
          li {
            display: inline-block;

            a {
              // color: #71c21b;
              color: #21309d;
              margin-right: 10px;
              font-size: 18px;
            }
          }
        }

        .logo {
          a {
            color: #283a5e;
            text-transform: uppercase;
            font-size: 30px;
            font-weight: 900;

            span {
              color: $template_color;
            }
          }
        }
      }
      .modal-header {
        border-bottom-color: transparent;
        background-color: transparent;
        padding: 20px 30px 0;

        .close {
          color: #000000;
          font-size: 35px;
          outline: 0 !important;
          transition: 0.4s;

          &:not(:disabled):not(.disabled):focus,
          &:not(:disabled):not(.disabled):hover {
            color: $template_color;
            text-decoration: none;
            opacity: 1;
          }
        }
      }
    }
  }
  &.fade {
    .modal-dialog {
      right: -420px;
      transition: opacity 0.3s linear, right 0.3s ease-out;
    }
    &.show {
      .modal-dialog {
        right: 0;
      }
    }
  }
}

/*================================================
Got Top CSS
=================================================*/
.ScrollUpButton__Container.ScrollUpButton__Toggled:hover {
  transition: 0.4s !important;
  background-color: $template_color !important;
}
.ScrollUpButton__Container {
  line-height: 1;
  &:focus {
    outline: 0;
  }
}

.loginForm {
  // border-bottom: 2px solid gray;
  border-radius: 2px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}
.loginForm:focus {
  background-color: lightgray;
  border-bottom: 2px solid gray;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}

.loginInput {
  border-bottom: 2px solid lightgray;
  background-color: white;
  border-radius: 2px;
}

.loginInput:focus {
  border-bottom: 2px solid gray;
  background-color: white;
  border-radius: 2px;
}

.model_title {
  padding-left: 20px;
  font-weight: 800;
}

.error {
  color: red;
}

.cardbtn {
  box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
}

.whiteSpaceNoWrap {
  white-space: nowrap;
}

.cardClass {
  border-radius: 2px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  padding: 2%;
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 769px) {
  .cardClass {
    // padding: 4%;
    // margin: 0px !important;

    // padding: 2%;
    // margin: 5%;
    // margin-top: 20%;

    border-radius: 2px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    padding: 2%;
    margin: 20px;
    min-height: 60px;
    // margin-top: 18%;
  }
}

@media (width: 768px) {
  .cardClass {
    border-radius: 2px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    padding: 2%;
    margin: 18px;
    margin-top: 6%;
  }
}

@media (width: 640px) {
  .cardClass {
    border-radius: 2px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    padding: 2%;
    // margin: 18px;
    margin-top: 6%;
  }
}

.cardTitle {
  padding-left: 1%;
}

.cardTable {
  border-radius: 2px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  margin-top: 2%;
  // padding: 2%;
}

.desktop-only {
  @media (max-width: 767px) {
    display: none;
  }
}

.mobile-only {
  @media (min-width: 769px) {
    display: none;
  }
  @media (min-width: 768px) {
    display: none;
  }
  .MuiOutlinedInput-input {
    padding-bottom: 4% !important;
    padding-top: 4% !important;
  }
}

.MuiButton-label {
  // color: wht;
  // &:hover {
  //     color: grey;
  // }
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
  text-transform: capitalize;

  &:hover {
    color: gray;
  }
}

.MuiButton-text {
  padding: 6px 12px !important;
}

.boxContact {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Toastify__toast--success {
  background: #21309d !important;
}

.Toastify__toast--warning {
  background: #21309d !important;
}

.Toastify__toast--info {
  background: #21309d !important;
}

.forgetPass {
  margin: 10%;
}

.makeStyles-table-3 thead th {
  color: black !important;
  font-weight: 600;
  background-color: white !important;
}

.makeStyles-formControl-3 {
  // margin: 8px;
  margin: 4px !important;
  min-width: 120px;
}

.iconSize {
  .MuiSvgIcon-root {
    width: 2em;
    height: 2em;
  }
}

@media (max-width: 350px) {
  .iconSize .MuiSvgIcon-root {
    width: 1em;
    height: 1em;
  }
}

.PopupModel {
  overflow-x: hidden;
}

.TableView {
  margin: 40px;
  padding: 1px;
  // position: sticky !important;
  overflow: scroll !important;
  // overflow-y: scroll; /* Show vertical scrollbar */
  // overflow-x: hidden; /* Show horizontal scrollbar */
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
  scrollbar-width: none !important; /* Firefox */
  ::-webkit-scroll {
    width: 1px;
  }
  // box-shadow: 0px 8px 15px gray;
}

.TableView::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
  width: 0px !important;
  background: transparent !important; /* make scrollbar transparent */
}

.headerClassSticky {
  position: sticky;
  top: 0;
}

// .headerClass {
//   position: sticky;
//   top: 0
// }
// .addStyle {
//   overflow-y: scroll;
// }

@media (max-width: 769px) {
  .TableView {
    margin: 20px;
    // padding: 24px;
    padding: 1px;
    padding-top: 0px;
    overflow: scroll !important;
    // overflow-y: hidden; /* Show vertical scrollbar */
    // overflow-x: scroll; /* Show horizontal scrollbar */
    // top: 0;

    ::-webkit-scroll {
      width: 1px;
    }
    // box-shadow: 0px 8px 15px gray;
  }
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
  /* color: blue; */
  color: blue;
  font-weight: 400;
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel {
  color: red;
  font-weight: 400;
}

/* start alertify.js confirm alert theme style */
.ajs-message {
  color: #21309d !important;
  background-color: #d9edf7;
  font-weight: normal !important;
  font-family: $font_family2 !important;
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
  color: #fff !important;
  outline-style: none !important;
  background-color: #21309d !important;
  font-weight: bold !important;
  font-family: $font_family2 !important;
  border-radius: 30px !important;
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel {
  color: #21309d !important;
  outline-style: none !important;
  background-color: #ffffff !important;
  font-weight: bold !important;
  font-family: $font_family2 !important;
  border-radius: 30px !important;
  border-color: #21309d;
  border: 2px solid #21309d;
  &:hover,
  &:focus {
    border-color: #ffffff;
    border: 2px solid #21309d;
    background-color: #21309d !important;
    color: #ffffff !important;
  }
}

.alertify .ajs-header {
  color: #000 !important;
  font-weight: bold !important;
  font-family: $font_family2 !important;
}
.alertify .ajs-body {
  font-weight: normal !important;
  font-family: $font_family2 !important;
}

.submitButton {
  color: #fff !important;
  outline-style: none !important;
  background-color: #21309d !important;
  font-weight: bold !important;
  font-family: $font_family2 !important;
  border-radius: 18px !important;
  border-color: #21309d;
  border: 2px solid #21309d;
}

.cancelButton {
  display: flex;
  color: #21309d !important;
  outline-style: none !important;
  background-color: #ffffff !important;
  font-weight: bold !important;
  font-family: $font_family2 !important;
  border-radius: 18px !important;
  border-color: #21309d;
  border: 2px solid #21309d;
  &:hover,
  &:focus {
    border-color: #21309d;
    // border: 2px solid #21309d;
    background-color: #21309d !important;
    color: #ffffff !important;
  }
}

.MuiTypography-h6 {
  font-weight: bold !important;
  font-family: $font_family2 !important;
}

.MuiInputBase-root {
  font-family: $font_family2 !important;

  legend {
    float: initial;
  }
}
/* end alertify.js confirm alert theme style */

// Table fixed

// .tableFixHeader {
//   overflow-y: auto;
//   max-height: 600px;
// }

// .tableFixHeader thead th {
//   position: sticky;
//   top: 0px;
//   z-index: 1;
// }

// /* Just common table stuff. Really. */
// table {
//   border-collapse: collapse;
//   width: 100%;
//   overflow: unset !important;
//   position: relative;
// }

// thead th {
//   // background: $ultra-light-grey !important;
//   box-shadow: 1px 0px 0px 1px black;
// }

// table th .css-yk16xz-control {
//   background: transparent;
// }

.alertify.ajs-closable .ajs-commands button.ajs-close {
  display: none;
}

// .navbar{
//   position: sticky !important;
// }

.menu-style {
  max-height: 12rem !important;
  // overflow: scroll !important;
  // overflow: hidden !important;
  overflow-y: scroll; /* Show vertical scrollbar */
  overflow-x: hidden; /* Show horizontal scrollbar */

  max-height: 12rem !important;
  overflow-y: scroll;
  overflow-x: hidden;

  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;

  ::-webkit-scroll {
    width: 1px;
  }
}

#dropdown-menu-align-right {
  // .btn-primary {
  color: #ffffff !important;
  background-color: #21309d !important;
  border-color: #21309d !important;
  padding-left: 2% !important;
  font-weight: 500;
  font-size: 17px;
  width: 70%;
  font-family: Quicksand, sans-serif;
  &:hover,
  &:focus {
    color: gray !important;
    background-color: #21309d !important;
    border-color: #21309d !important;
    padding-left: 2% !important;
    font-weight: 500;
    width: 70%;
    font-size: 17px;
    font-family: Quicksand, sans-serif;
  }
}

.dropdownBtn {
  padding-right: 4%;
  padding: 12%;
}

.dropdown {
  position: inherit;
}

#dropdown-menu-align-right {
  padding-top: 8%;
}

.mailDivStyle {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 0.2rem !important;
}

.registrationStyle {
  // padding-top: 2%;
  // padding-bottom: 2%;
  padding: 2%;
}

.num-of-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.num-of-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.num-of-line-2:hover {
  overflow: initial;
  word-break: break-all !important;
}

.userCountry {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.userCountry:hover {
  overflow: initial;
  word-break: break-all !important;
}

.Analytic_table {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 25px;
  // box-shadow: 5px 7px 15px -5px rgba(0, 0, 0, 0.3);
  border: 1 px solid black;
  margin-bottom: 10rem;
  margin: 40px;
}

@media (max-width: 769px) {
  .Analytic_table {
    margin: 20px;
  }
}

.tabs_cls {
  margin-left: 550px;
  margin-right: 550px;
  padding-top: 2px;
  // border-radius: 2px;
  // border: 1px solid white;
  box-shadow: 5px 7px 15px -5px rgba(0, 0, 0, 0.3);
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #21309d !important;
}

// .MuiInputBase-root .MuiInput-root .MuiInput-underline .MuiInputBase-formControl .MuiInput-formControl{
//   width: 100%;
// }
label + .MuiInput-formControl {
  margin-top: 16px;
  width: 100%;
}

// .MuiInput-root {
//   position: relative;
//   width: 100%;
// }

.tabs-class div {
  display: inline-table;
  align-content: right;
}

.custom-button {
  display: inline-block;
  margin: 10px;
}

.custom-button:focus {
  /* Provide a fallback style for browsers
     that don't support :focus-visible */
  outline: 2px solid red;
  background: lightgrey;
}

.custom-button:focus:not(:focus-visible) {
  /* Remove the focus indicator on mouse-focus for browsers
     that do support :focus-visible */
  background: transparent;
}

.custom-button:focus-visible {
  /* Draw a very noticeable focus style for
     keyboard-focus on browsers that do support
     :focus-visible */
  outline: 4px dashed darkorange;
  background: transparent;
}

@media (max-width: 769px) {
  .comp_btn .MuiTypography-h4 {
    font-size: 1.25rem;
    padding-bottom: 2%;
  }

  .comp_btn .MuiButton-label {
    font-size: 11px;
    font-weight: 600;
  }

  .comp_btn .shadow-none {
    padding-left: 10%;
    padding-right: 10%;
    float: right;
    background-color: rgb(33, 48, 157);
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .MuiTypography-h4 {
    font-size: 1.7 rem !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
  }
}

@media (max-width: 639px) {
  .comp_btn .MuiTypography-h4 {
    font-size: 1.25rem;
    padding-bottom: 2%;
    padding-top: 2%;
  }

  .comp_btn .MuiButton-label {
    font-size: 11px;
    font-weight: 600;
  }
  .comp_btn .shadow-none {
    padding-left: 10%;
    padding-right: 10%;
    float: right;
    background-color: rgb(33, 48, 157);
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .MuiTypography-h4 {
    font-size: 1.875rem !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
  }
}

@media (width: 320px) {
  .comp_btn .MuiTypography-h4 {
    font-size: 1.25rem;
    padding-bottom: 2%;
    padding-top: 2%;
  }
  .comp_btn .shadow-none {
    padding-left: 10%;
    padding-right: 10%;
    float: right;
    background-color: rgb(33, 48, 157);
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .MuiTypography-h4 {
    font-size: 1.7 rem !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
  }
}

@media (width: 768px) {
  .comp_btn .MuiTypography-h4 {
    font-size: 1.25rem;
    padding-bottom: 2%;
    padding-top: 2%;
  }

  .comp_btn .MuiButton-label {
    font-size: 11px;
    font-weight: 600;
  }
  .comp_btn .shadow-none {
    padding-left: 10%;
    padding-right: 10%;
    float: right;
    background-color: rgb(33, 48, 157);
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .MuiTypography-h4 {
    font-size: 1.7 rem !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
  }
}

@media (width: 320px) {
  .comp_btn .MuiTypography-h4 {
    font-size: 1rem;
    padding-bottom: 2%;
    padding-top: 2%;
  }

  .comp_btn .MuiButton-label {
    font-size: 11px;
    font-weight: 600;
  }
  .comp_btn .shadow-none {
    padding-left: 10%;
    padding-right: 10%;
    float: right;
    background-color: rgb(33, 48, 157);
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .MuiTypography-h4 {
    font-size: 1.7 rem !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
  }
}

@media (width: 640px) {
  .comp_btn .MuiTypography-h4 {
    font-size: 1.25rem;
    padding-bottom: 2%;
    padding-top: 2%;
  }
  .comp_btn .shadow-none {
    padding-left: 10%;
    padding-right: 10%;
    float: right;
    background-color: rgb(33, 48, 157);
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .MuiTypography-h4 {
    font-size: 1.7 rem !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
  }
}

.navHedaer a {
  color: #ffffff !important;
  font-family: $font_family2 !important;
  font-weight: 500;
  font-size: 17px !important;
  cursor: pointer;
  text-transform: capitalize;

  &.active {
    color: gray !important;
  }
}

// .navHedaer{
//  padding-left: 2%;
//  padding-right: 2%;
// }

.MuiTableCell-root {
  padding: 14px 8px !important;
}

@media (max-width: 1024px) {
  .MuiTableCell-root {
    padding: 14px 8px !important;
  }
}

.error {
  padding-top: 2%;
}

.MuiNativeSelect-select {
  min-width: 100px !important;
}

div.fade.in.modal {
  display: flex !important;
}

.modal-dialog {
  margin: auto;
  padding: 2%;
}

.alignMenuRight {
  position: absolute;
  right: 0px;
  margin-top: -15px;
  // ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
  //   display:'none'
  // }
}

.alignMenuRightMob {
  position: absolute;
  right: 0px;
  float: right;
  margin-right: -20px;
  // margin-top:-15px;
  // ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
  //   display:'none'
  // }
}

// .MuiDialog-paperWidthMd{
//   max-width:2000px !important;
//   width: 100%;
// }

.EditRecordTable {
  overflow-x: hidden !important;
}

.centerContain {
  display: flex;
  justify-content: space-between;
}

.ResearchText {
  font-weight: 500;
  font-size: 18px;
}

.CompPopup {
  // width:600px;
  width: 700px;
  // padding: 3%;
}

.TitleText {
  font-size: 30px;
  font-weight: 400;
  color: black;
}

.TextStyleSideNav {
  font-size: 19px;
}

.FooterBarCopy {
  padding-top: 4px;
}

.SubheadColor {
  color: #1e9cd6;
}

.MuiNativeSelect-select.MuiNativeSelect-select {
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

// /*-----------------*/
// /***** Goliath *****/
// /*-----------------*/

// figure.effect-goliath {
//   background:  rgb(33, 48, 157);

// }

// figure.effect-goliath img,
// figure.effect-goliath h2 {
// 	-webkit-transition: -webkit-transform 0.35s;
// 	transition: transform 0.35s;
// }

// figure.effect-goliath img {
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// }

// figure.effect-goliath h2,
// figure.effect-goliath p {
// 	position: absolute;
// 	bottom: 0;
// 	left: 0;
// 	padding: 30px;
// }

// figure.effect-goliath p {
// 	text-transform: none;
// 	font-size: 90%;
// 	opacity: 0;
// 	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
// 	transition: opacity 0.35s, transform 0.35s;
// 	-webkit-transform: translate3d(0,50px,0);
// 	transform: translate3d(0,50px,0);
// }

// figure.effect-goliath:hover img {
// 	-webkit-transform: translate3d(0,-80px,0);
// 	transform: translate3d(0,-80px,0);
// }

// figure.effect-goliath:hover h2 {
// 	-webkit-transform: translate3d(0,-100px,0);
// 	transform: translate3d(0,-100px,0);
// }

// figure.effect-goliath:hover p {
// 	opacity: 1;
// 	-webkit-transform: translate3d(0,0,0);
// 	transform: translate3d(0,0,0);
// }

// /* Header */
// .codrops-header {
// 	margin: 0 auto;
// 	padding: 4em 1em;
// 	text-align: center;
// }

// .codrops-header h1 {
// 	margin: 0;
// 	font-weight: 800;
// 	font-size: 4em;
// 	line-height: 1.3;
// }

// .codrops-header h1 span {
// 	display: block;
// 	padding: 0 0 0.6em 0.1em;
// 	color: #ffff;
// 	font-weight: 300;
// 	font-size: 45%;
// }

// /* Demo links */
// .codrops-demos {
// 	clear: both;
// 	padding: 1em 0 0;
// 	text-align: center;
// }

// .content + .codrops-demos {
// 	padding-top: 5em;
// }

// .codrops-demos a {
// 	display: inline-block;
// 	margin: 0 5px;
// 	padding: 1em 1.5em;
// 	text-transform: uppercase;
// 	font-weight: bold;
// }

// .codrops-demos a:hover,
// .codrops-demos a:focus,
// .codrops-demos a.current-demo {
// 	background: #3c414a;
// 	color: #fff;
// }

// /* To Navigation Style */
// .codrops-top {
// 	width: 100%;
// 	text-transform: uppercase;
// 	font-weight: 800;
// 	font-size: 0.69em;
// 	line-height: 2.2;
// }

// .codrops-top a {
// 	display: inline-block;
// 	padding: 1em 2em;
// 	text-decoration: none;
// 	letter-spacing: 1px;
// }

// .codrops-top span.right {
// 	float: right;
// }

// .codrops-top span.right a {
// 	display: block;
// 	float: left;
// }

// .codrops-icon:before {
// 	margin: 0 4px;
// 	text-transform: none;
// 	font-weight: normal;
// 	font-style: normal;
// 	font-variant: normal;
// 	font-family: 'codropsicons';
// 	line-height: 1;
// 	speak: none;
// 	-webkit-font-smoothing: antialiased;
// }

// .codrops-icon-drop:before {
// 	content: "\e001";
// }

// .codrops-icon-prev:before {
// 	content: "\e004";
// }

// /* Related demos */
// .related {
// 	clear: both;
// 	padding: 6em 1em;
// 	font-size: 120%;
// }

// .related > a {
// 	display: inline-block;
// 	margin: 20px 10px;
// 	padding: 25px;
// 	border: 1px solid #4f7f90;
// 	text-align: center;
// }

// .related a:hover {
// 	border-color: #39545e;
// }

// .related a img {
// 	max-width: 100%;
// 	opacity: 0.8;
// }

// .related a:hover img,
// .related a:active img {
// 	opacity: 1;
// }

// .related a h3 {
// 	margin: 0;
// 	padding: 0.5em 0 0.3em;
// 	max-width: 300px;
// 	text-align: left;
// }

// /* Demo ad design */
// body #cdawrap {
// 	background: none;
// 	top: 50px;
// 	border: none;
// }

// body #cdawrap a {
// 	color: #fff !important;
// }

// body #cda-remove {
// 	color: #fff;
// }

// @media screen and (max-width: 25em) {
// 	.codrops-header {
// 		font-size: 75%;
// 	}
// 	.codrops-icon span {
// 		display: none;
// 	}
// }

// .grid figure figcaption > a {
// 	z-index: 1000;
// 	text-indent: 200%;
// 	white-space: nowrap;
// 	font-size: 0;
// 	opacity: 0;
// }

// .grid figure h2 {
// 	word-spacing: -0.15em;
// 	font-weight: 300;
// }

// .grid figure h2 span {
// 	font-weight: 800;
// }

// .grid figure h2,
// .grid figure p {
//   margin: 0;
//   line-height: 1 !important
// }

// .grid figure p {
// 	letter-spacing: 1px;
//   font-size: 68.5%;
//   line-height: 1 !important
// }

/* The flip box container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */

.flip-box-inner {
  @media only screen and (max-width: 768px) {
    height: 360px !important;
    .flip-box-inner {
      height: 360px !important;
    }
  }
}

.flip-box {
  background-color: transparent;
  width: 100%;
  height: auto;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 560px;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-box-front {
  background-color: #f1f1f1;
  color: black;
  height: 100%;
}

/* Style the back side */
.flip-box-back {
  background-color: lightgray;
  color: white;
  height: 100%;
  transform: rotateY(180deg);
}

// ================================ New card =========================

.cardBox {
  float: left;
  font-size: 1.2em;
  margin: 1% 0 0 1%;
  perspective: 800px;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.cardBox:hover .card {
  transform: rotateY(180deg);
}

.card {
  // background: #222;
  cursor: default;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 100%;
  -webkit-animation: giro 1s 1;
  animation: giro 1s 1;
}

.card p {
  margin-bottom: 1.8em;
}

.card .front,
.card .back {
  backface-visibility: hidden;
  box-sizing: border-box;
  color: white;
  display: block;
  font-size: 1.2em;
  height: 100%;
  padding: 0.8em;
  position: absolute;
  text-align: center;
  width: 100%;
}

.card .front strong {
  background: #fff;
  // border-radius: 100%;
  color: #fff;
  font-size: 1.5em;
  line-height: 30px;
  padding: 0 7px 4px 6px;
}

.card .back {
  transform: rotateY(-180deg);
}

.card .back a {
  padding: 0.3em 0.5em;
  background: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 1px;
  font-size: 0.9em;
  transition: all 0.2s ease 0s;
}

.card .back p {
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  text-align: left;
}

.card .back a:hover {
  background: #fff;
  color: #fff;
  text-shadow: 0 0 1px #fff;
}

.cardBox:nth-child(1) .card .back {
  background: rgb(33, 48, 157);
}

.cardBox:nth-child(2) .card .back {
  background: orange;
}

.cardBox:nth-child(3) .card .back {
  background: yellowgreen;
}

.cardBox:nth-child(4) .card .back {
  background: tomato;
}

.cardBox:nth-child(2) .card {
  -webkit-animation: giro 1.5s 1;
  animation: giro 1.5s 1;
  // animation: giro 60s 1;
  // -webkit-animation: giro 60s 1;
}

.cardBox:nth-child(3) .card {
  -webkit-animation: giro 2s 1;
  animation: giro 2s 1;
  // animation: giro 60s 1;
  // -webkit-animation: giro 60s 1;
}

.cardBox:nth-child(4) .card {
  -webkit-animation: giro 2.5s 1;
  animation: giro 2.5s 1;
  // animation: giro 60s 1;
  // -webkit-animation: giro 60s 1;
}

@-webkit-keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .cardBox {
    margin-left: 2.8%;
    margin-top: 3%;
    width: 100%;
  }
  .card {
    height: 100%;
  }
  .cardBox:last-child {
    margin-bottom: 3%;
  }
}

@media screen and (width: 640px) {
  .card .back p {
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    text-align: left;
    font-size: 8px;
  }
}

@media screen and (width: 768px) {
  .card .back p {
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    text-align: left;
    font-size: 8px;
  }
}

@media screen and (width: 1024px) {
  .card .back p {
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    text-align: left;
    font-size: 8px;
  }
}

@media screen and (max-width: 365px) {
  .card .back p {
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    text-align: left;
    font-size: 10px;
  }
}

@media screen and (max-width: 480px) {
  .cardBox {
    width: 100%;
  }
  .card {
    height: 100%;
  }
}

.FixColumn {
  max-width: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;

  // &:hover {
  //   max-width: 20rem;
  //   overflow: visible;
  // }
}

.LinkButton {
  color: #212529;
  &:hover {
    color: #21309d;
  }
}

@media screen and (max-width: 767px) {
  .cardBox {
    margin-left: 2.8%;
    margin-top: 3%;
    width: 100%;
  }
  .card {
    height: 100%;
  }
  .cardBox:last-child {
    margin-bottom: 3%;
  }
}

@media screen and (width: 640px) {
  .flippy-back p {
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    text-align: left;
    font-size: 8px;
  }
}

@media screen and (width: 768px) {
  .flippy-back p {
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    text-align: left;
    font-size: 8px;
  }
}

@media screen and (width: 1024px) {
  .flippy-back p {
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    text-align: left;
    font-size: 8px;
  }
}

@media screen and (max-width: 365px) {
  .flippy-back p {
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    text-align: left;
    font-size: 10px;
  }
}

@media screen and (max-width: 480px) {
  .cardBox {
    width: 100%;
  }
  .card {
    height: 100%;
  }
}

.flippy-front {
  padding: 0em !important;
}
