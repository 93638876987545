@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$grid-gutter-width: 30px;
$container-padding-x: $grid-gutter-width;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

@import '~bootstrap/scss/bootstrap';
